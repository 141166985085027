import React, { FC }  from 'react';
import SEO from 'components/seo';
import Layout from 'containers/layout/layout';
import Header from 'containers/layout/header';
import Footer from 'containers/layout/footer';
import Hero from 'components/hero';
import Callout from 'components/callout';
import { graphql, useStaticQuery } from 'gatsby';
import Button from 'components/ui/button';
import styled from 'styled-components';

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 0 50px;
`;

interface Props {
  location: string;
}

const WhitepaperSignupSuccess: FC<Props> = ({ location }) => {
  const siteInfo = useStaticQuery(graphql`
    query subscriptionSuccessSiteInfoQuery {
      site {
        siteMetadata {
          titleTemplate
          contact {
            fullAddress {
              street
              city
              province
              postalCode
            }
          }
        }
      }
    }
  `);
  const fullAddress = siteInfo?.site?.siteMetadata?.contact?.fullAddress;
  const title = siteInfo?.site?.siteMetadata?.titleTemplate;

  const body = `
  Your subscription to our newsletter mailing list is confirmed (and if you downloaded a white paper it should be on its way to you).
  
  ${title}  
  ${fullAddress.street}  
  ${fullAddress.city}, ${fullAddress.province}  
  ${fullAddress.postalCode}
  
  Thank you for subscribing!
  `;

  return (
    <Layout location={location}>
      <SEO title="Thank you for signing up" />
      <Header />
      <main className="site-wrapper-reveal">
        <Hero title={''} hero="trust" />
        <Callout title="Subscription Confirmation" body={body} />
        <ButtonsContainer>
          <Button
            to="https://atbventures.com"
            varient="texted"
            m="12px 0"
            style={{
              width: '100%',
            }}
          >
            ATB Ventures homepage
          </Button>
          <Button
            to="https://atbventures.us4.list-manage.com/unsubscribe?u=ba96a1c6f9fa269660f4104b4&id=ba1454213b"
            varient="texted"
            m="12px 0"
            style={{
              width: '100%',
            }}
          >
            Unsubscribe
          </Button>
        </ButtonsContainer>
      </main>
      <Footer />
    </Layout>
  );
};

export default WhitepaperSignupSuccess;
